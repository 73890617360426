import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { roleHelper, homeOwnerService } from '@/main';
import Place from '@/models/Place';
import to from 'await-to-js';
import { $router } from '@/router';
import Vue from 'vue';
import { memberModule } from '@/store/modules/member';

@Component
export default class HomeOwnerMyAccosPage extends BasePage {
    public places: Place[] = [];
    public placesGridColumns = [
        { field: 'lotNumber', title: 'Pleknummer', width: 120, editable: false, cell: this.renderLotNumber },
        { field: 'name', title: 'Naam', editable: false, cell: this.renderAccomodationName },
        { field: 'siteName', title: 'Park', editable: false },
        { cell: this.renderActions, title: 'Actions', width: 150 },
    ];

    public async created() {
        const homeOwnerId = memberModule.member.id;
        const [err, response] = await to(homeOwnerService.getHomeOwner(homeOwnerId));
        if (err) {
            this.showFailedResponse('Mislukt om accommodaties op te halen.', err);
        }

        this.places = response.data.places;
        this.isLoading = false;
    }

    public renderActions(h, a, row: any): any {
        const actions = [{ title: 'Bekijk', function: this.goToAcco }];
        const props = { actions, item: row.dataItem };
        return h(Vue.component('grid-actions'), { props });
    }

    public renderAccomodationName(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'my-home-owner-accommodation',
            params: { placeId },
        });
        const props = { text: item.placeName, url: route.href };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin() || roleHelper.isRecreapiHomeOwner()) {
            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', item.name);
    }

    public goToAcco(item: Place): any {
        $router.push({ name: 'my-home-owner-accommodation', params: { placeId: item.placeId.toString() } });
    }

    public renderLotNumber(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'my-home-owner-accommodation',
            params: { placeId },
        });
        const props = { text: item.lotNumber, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }
}
