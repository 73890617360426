import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { roleHelper, homeOwnerService, calendarService } from '@/main';
import Place from '@/models/Place';
import to from 'await-to-js';
import { $router } from '@/router';
import Vue from 'vue';
import moment from 'moment';
import { EventCategory } from '@/models/EventCategory';
import { AxiosResponse } from 'axios';
// import $ from 'jquery';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min.js';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@Component
export default class HomeOwnerMyAccoPage extends BasePage {
    public place: Place = new Place();
    public selectedDates: any = null;
    public calendar: any = { days: [] };

    public attrs: any = [
        {
            isInline: true,
            key: 'today',
            dates: new Date(),
        },
    ];

    public $refs!: {
        galery: any;
    };

    private imageIndex: number = 0;

    public async created() {
        const placeId = this.$route.params.placeId;
        const [err, response] = await to(homeOwnerService.getAccommodation(parseInt(placeId, 10)));

        if (err) {
            this.showFailedResponse('Mislukt om accommodaties op te halen.', err);
        }

        this.place = response.data;

        await this.loadCalendarEvents();

        this.isLoading = false;
    }

    public renderAccomodationName(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'my-home-owner-accommodation',
            params: { placeId },
        });
        const props = { text: item.placeName, url: route.href };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin() || roleHelper.isRecreapiHomeOwner()) {
            return h(Vue.component('grid-router-link'), { props });
        }

        return h('td', item.name);
    }

    public goToAcco(item: Place): any {
        $router.push({ name: 'acco', params: { placeKey: item.placeId.toString() } });
    }
    public editAcco(item: Place): any {
        $router.push({ name: 'acco', params: { placeKey: item.placeId.toString(), mode: 'edit' } });
    }

    public renderLotNumber(h, a, row) {
        const item = row.dataItem;
        const placeId = item.placeId ? item.placeId.toString() : '';
        const route = $router.resolve({
            name: 'my-home-owner-accommodation',
            params: { placeId },
        });
        const props = { text: item.lotNumber, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public async loadCalendarEvents() {
        const calendarResponse = await this.getCalendarData();
        this.calendar = calendarResponse;
        this.loadCalendar();
    }

    public async getCalendarData() {
        const [err, calendar] = await to<AxiosResponse>(calendarService.getPlaceCalendar(this.place.placeId));
        if (err) {
            this.showFailedResponse('Mislukt om agenda op te halen', err);
            return [];
        }

        return (this.calendar = calendar.data);
    }

    public loadCalendar() {
        this.attrs = [
            {
                isInline: true,
                key: 'today',
                dates: new Date(),
            },
        ];

        const prices = this.calendar.days.reduce((t: any, day: any) => {
            if (day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.NationalHoliday)) {
                const event = day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.NationalHoliday);
                t.push({
                    dates: moment(day.day, 'YYYY-MM-DD').toDate(),
                    popover: {
                        label: event.eventType.name + ': ' + event.name,
                    },
                    bar: {
                        backgroundColor: event.eventType.eventTypeId === 1 ? 'green' : 'blue', // Red bar
                    },
                });
            }

            if (day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation)) {
                const event = day.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation);
                t.push({
                    dates: moment(day.day, 'YYYY-MM-DD').toDate(),
                    popover: {
                        label: 'Niet beschikbaar: ' + event.name,
                    },
                    bar: {
                        backgroundColor: 'red',
                    },
                });
            }

            return t;
        }, []);

        this.attrs = this.attrs.concat(prices);
    }

    public book() {
        const start = moment(this.selectedDates.start).format('YYYY-MM-DD');
        const end = moment(this.selectedDates.end).format('YYYY-MM-DD');

        $router.push({
            name: 'my-home-owner-book-accommodation',
            params: {
                placeId: this.place.placeId.toString(),
            },
            query: {
                fromDate: start,
                toDate: end,
            },
        });
    }

    public get hasBookingInRange() {
        const startDate = moment(this.selectedDates.start);
        const endDate = moment(this.selectedDates.end).add('d', 1);
        let hasBookingInRange = false;

        this.calendar.days.forEach((x) => {
            if (
                x.entries.find((entry) => entry.eventType.eventCategory === EventCategory.Occupation && entry.name === 'Booked') &&
                !hasBookingInRange
            ) {
                hasBookingInRange = moment(x.day).isBetween(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
                if (hasBookingInRange) {
                    return;
                }
            }
        });

        return hasBookingInRange;
    }

    public previous() {
        if (this.imageIndex > 0) {
            this.imageIndex--;
            this.scroll();
        }
    }

    public next() {
        if (this.imageIndex < this.place.placeType.images.length) {
            this.imageIndex++;
            this.scroll();
        }
    }

    public openImages() {
        const imageList = [];
        this.place.placeType.images.forEach((image) => {
            imageList.push({
                type: 'image',
                src: image.url,
            });
        });

        const jquery = $ as any;
        jquery.fancybox.open(imageList);
    }

    private scroll() {
        const galery = this.$refs.galery;
        const childs = galery.childNodes;
        let width = 0;

        if (galery && childs.length > 0) {
            width = childs[0].clientWidth;
            galery.scrollLeft = width * this.imageIndex;
        }
    }
}
