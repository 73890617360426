import { Invoice } from './Invoice';

export default class InvoicePaymentLink {
    public invoice: Invoice = new Invoice();
    public paymentLink: string = '';

    constructor(obj?: Partial<InvoicePaymentLink>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
