import BasePage from '../../models/BasePage';
import Component from 'vue-class-component';
import { homeOwnerService } from '@/main';
import to from 'await-to-js';
import Booking from '../../models/Booking';
import HomeOwner from '../../models/HomeOwner';
import { memberModule } from '@/store/modules/member';

@Component
export default class HomeOwnerMyInfoPage extends BasePage {
    public homeOwner: HomeOwner = new HomeOwner({ address: {} });
    public bookings: Booking[] = [];

    public async mounted() {
        const homeOwnerId = memberModule.member.id;
        const [err, response] = await to(homeOwnerService.getHomeOwner(homeOwnerId));
        if (err) {
            return this.showError('Huiseigenaar ophalen mislukt');
        }

        this.homeOwner = new HomeOwner(response.data);
        this.isLoading = false;
    }

    public getFullName() {
        return `${this.homeOwner.firstName}${this.homeOwner.insertion ? ' ' + this.homeOwner.insertion : ''} ${this.homeOwner.lastName}`;
    }

    public async save() {
        this.showPending('Boeker opslaan...');
        const [err, response] = await to(homeOwnerService.updateHomeOwner(this.homeOwner));
        if (err || !response) {
            this.clearAndShowError('Mislukt om boeker gegevens op te slaan.', err);
        } else {
            Object.assign(this.homeOwner, response.data);
            this.clearAndShowSuccess('Boeker succesvol opgeslagen.');
            this.cancelEdit();
        }
    }
}
