import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { Invoice } from '@/models/Invoice';
import Vue from 'vue';
import { dictionaryHelper, homeOwnerService, invoiceService } from '@/main';
import moment from 'moment/min/moment.min.js';
import to from 'await-to-js';
import { BModal } from 'bootstrap-vue';
import InvoicePaymentLink from '@/models/PaymentLink';
import { memberModule } from '@/store/modules/member';

@Component
export default class HomeOwnerMyInvoicesPage extends BasePage {
    public invoices: Invoice[] = [];
    public invoicePaymentLink: InvoicePaymentLink = new InvoicePaymentLink();

    public $refs!: {
        idealLinkModal: BModal;
    };

    public get homeOwnerId() {
        return memberModule.member.id;
    }

    public gridColumns = [
        { field: 'invoiceNumber', title: 'Factuurnummer' },
        { field: 'invoiceDate', title: 'Factuurdatum', cell: this.renderInvoiceDate },
        { field: 'dueDate', title: 'Verloopdatum', cell: this.renderDueDate },
        { field: 'totalAmount', title: 'Bedrag', cell: this.renderValuta },
        { title: 'Openstaand bedrag', cell: this.renderOpenstaandValuta },
        { title: 'Status', field: 'status' },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: '150px' },
    ];

    public async created() {
        this.invoices = await this.loadInvoices();
        this.isLoading = false;
    }

    public async loadInvoices() {
        const [err, response] = await to(homeOwnerService.getInvoices(this.homeOwnerId));
        if (err) {
            this.showError('Mislukt om de facturen op te halen');
        }
        return response.data;
    }

    public renderOpenstaandValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount - row.dataItem.amountPaid, 'c'));
    }

    public renderValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount, 'c'));
    }

    public renderInvoiceDate(h: any, a, row) {
        return h('td', moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }

    public renderDueDate(h: any, a, row) {
        if (row.dataItem.dueDate) {
            return h('td', moment(row.dataItem.dueDate).format('DD-MM-YYYY'));
        }

        return h('td', '-');
    }

    public async getIdealLink(invoice: any) {
        const invoiceId = invoice.invoiceId;

        this.showPending('Betaallink ophalen..');

        const [err, response] = await to(homeOwnerService.getIdealLink(invoiceId));
        if (err) {
            return this.clearAndShowError('Betaallink ophalen is mislukt', err);
        }

        invoice.status = dictionaryHelper.get('InvoiceStates')[2];
        this.invoicePaymentLink = new InvoicePaymentLink(response.data);
        this.clearNotifications();

        this.$refs.idealLinkModal.show();
        this.editMode = false;
    }

    public async downloadInvoice(invoice) {
        const self = this;

        self.showPending('Factuur download voorbereiden...');

        const [err, response] = await to(invoiceService.downloadInvoice(invoice.invoiceId));
        if (err) {
            return self.clearAndShowError('Factuur downloaden mislukt.', err);
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.invoiceNumber}-${new Date().toLocaleTimeString()}.pdf`);
        document.body.appendChild(link);
        link.click();

        self.clearAndShowSuccess('Factuur gedownload.');
    }

    private renderActions(h: any, a, row) {
        const status = row.dataItem.status;
        const actions = [];

        if (status === 6) {
            return h('td');
        }

        if (status !== 6) {
            actions.push({ title: 'Download factuur als PDF', function: this.downloadInvoice });
        }

        if (status === 'Open' && row.dataItem.totalAmount > 0) {
            actions.push({ title: 'Haal betaallink op', function: this.getIdealLink });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }
}
